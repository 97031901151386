<template>
    <div>
        <b-card no-body class="card-app-design mb-0 bb-lrr-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Date Interval" label-for="dateInterval">
                            <flat-pickr v-model="searchIntervalDate"
                                        :config="{ mode: 'range',defaultDate:[searchReportDiffMonthDate,searchReportDate]}"
                                        class="form-control" placeholder="YYYY-MM-DD | YYYY-MM-DD"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Role" label-for="role">
                            <v-select
                                v-model="roleFilter"
                                :reduce="(option) => option.value"
                                label="label"
                                :options="roleOptions"
                                :clearable="false"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Team" label-for="team">
                            <v-select v-if="teamList"
                                      :options="teamList" :reduce="(option) => option.value"
                                      v-model="teamFilterUser"
                                      placeholder="Select Team" class="w-100"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6 my-auto" v-if="bitbucketUsers">
                        <div class="design-planning-wrapper">
                            <div class="design-planning mb-0">
                                <p class="card-text mb-25">
                                    Repositories
                                </p>
                                <h6 class="mb-0">
                                    {{ bitbucketUsers.data.total_repo }}
                                </h6>
                            </div>
                            <div class="design-planning mb-0">
                                <p class="card-text mb-25">
                                    Total Commit
                                </p>
                                <h6 class="mb-0">
                                    {{ bitbucketUsers.data.total_commit }}
                                </h6>
                            </div>
                            <div class="design-planning mb-0">
                                <p class="card-text mb-25">
                                    Member
                                </p>
                                <h6 class="mb-0">
                                    {{ bitbucketUsers.data.users.length }}
                                </h6>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card no-body id="guruTableScroll" class="mb-0 mt-0" v-if="!isLoading">
            <div class="guru" v-if="bitbucketUsers">
                <div class="guru_row guru_row-hours sticky"
                     :style="'grid-template-columns: 150px repeat('+bitbucketUsers.data.grid+', 1fr);'">
                    <div class="guru_row-first  d-flex  align-items-center">Name</div>
                    <span v-for="date in bitbucketUsers.data.periods" :key="date.id">{{ formatDate(date) }}</span>
                </div>
                <div class="guru_row guru_row-lines"
                     :style="'grid-template-columns: 150px repeat('+bitbucketUsers.data.grid+', 1fr);'">
                    <span></span>
                    <span v-for="date in bitbucketUsers.data.periods" :key="date.id"></span>
                </div>
                <div class="guru_row" v-for="userlist in sortedBitbucketUsers" :key="userlist.id"
                     ref="refDiscordUsers">
                    <div class="guru_row-first">
                        <b-media vertical-align="center">
                            <template #aside>
                                <b-avatar size="26" :src="userlist.image" :text="avatarText(userlist.name)"/>
                            </template>
                            <b-link class="font-weight-bold d-block text-nowrap">
                                {{ userlist.name }}
                            </b-link>
                            <small class="text-muted">Total: {{ userlist.total_commit }}</small>
                        </b-media>
                    </div>
                    <ul class="guru_row-bars p-0"
                        :style="'grid-template-columns: repeat('+bitbucketUsers.data.grid+', 1fr)!important;'">
                        <li v-for="(commit,index) in userlist.commits.filter(item => item.count > 0)" :key="index"
                            class="m-20 justify-content-center"
                            @click="commit.count>0?getDetailInfo(userlist,commit.date):null"
                            :style="columnFormat(commit.key,commit.count,userlist.total_commit)"
                            v-b-tooltip.html.hover.top="'Commit: '+commit.count+'<br/>Date: '+commit.date">
                            {{ commit.count }}
                        </li>
                    </ul>
                </div>
            </div>
            <bitbucket-user-detail ref="modal" :modalDate="modalDate"></bitbucket-user-detail>
        </b-card>
        <b-card v-if="isLoading" class="mt-2">
            <div class="text-center my-2">
                <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </b-card>

        <b-card v-if="!isLoading && !bitbucketUsers" class="mt-2">
            <div class="d-flex align-items-center">
                <strong>Not Found.</strong>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BOverlay, BFormGroup, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {avatarText} from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import useBitbucketApi from '@/composables/useBitbucketApi'
import moment from 'moment'
import BitbucketUserDetail from '@/components/BitbucketUserDetail.vue'

export default {
    components: {
        vSelect,
        BFormGroup,
        BSpinner,
        BOverlay,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        flatPickr,
        BitbucketUserDetail
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            modalDate: null,
        }
    },
    setup() {
        const {
            bitbucketUsers,
            fetchBitbucketUsers,
            isLoading,
            refbitbucketUsers,
            searchReportDate,
            searchIntervalDate,
            fetchDetailView,
            searchReportDiffMonthDate,
            fetchBitbucketUserDetail,
            modalLoading,
            roleOptions,
            roleFilter,
            teamFilterUser,
            teamList,
            fetchTeamList,
        } = useBitbucketApi()
        return {
            bitbucketUsers,
            fetchBitbucketUsers,
            isLoading,
            refbitbucketUsers,
            searchReportDate,
            searchIntervalDate,
            fetchDetailView,
            searchReportDiffMonthDate,
            avatarText,
            fetchBitbucketUserDetail,
            modalLoading,
            roleOptions,
            roleFilter,
            teamFilterUser,
            teamList,
            fetchTeamList,
        }
    },
    mounted() {
        this.fetchBitbucketUsers()
        this.fetchTeamList()
    },
    methods: {
        formatDate(date) {
            return moment(date).format('DD.MM')
        },
        columnFormat(key, count, total) {
            if (count == 0) return 'grid-column:' + key + ';background:none'
            let normalize = (count - 0) / (10 - 0)
            let colorText = normalize < 0.5 ? "#232323" : '#f7f7f7'
            let background = `rgb(9, 191, 44, ${normalize})`;
            return `grid-column:${key};color:${colorText};background:${background}`
        },
        getDetailInfo(user, date) {
            this.modalDate = date
            this.fetchBitbucketUserDetail(user.id, date)
            this.$root.$emit("bv::show::modal", "modal-primary")
        },
    },
    computed: {
        sortedBitbucketUsers: function () {
            return this.bitbucketUsers.data.users.sort((a, b) => a.name.localeCompare(b.name));
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.tooltip-inner {
    text-align: left;
}
</style>
