<template>
    <b-modal id="modal-primary" hide-footer modal-class="modal-primary" centered @hidden="resetDetail">
        <b-card-text v-if="!isLoading">
            <div class="row" v-if="commitDetail">
                <div class="col-12 card-app-design">
                    <b-media no-body class="mb-1">
                        <b-media-aside class="mr-75 my-auto">
                            <b-avatar size="36" :src="commitDetail.image" :text="avatarText(commitDetail.name)" />
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                {{ commitDetail.name }}
                            </h6>
                            <small>{{modalDate}}</small>
                        </b-media-body>
                        <div class="design-planning-wrapper float-right">
                            <div class="design-planning mb-0">
                                <p class="card-text mb-25">
                                    Total Commit
                                </p>
                                <h6 class="mb-0">
                                    {{commitDetail.total_commit}}
                                </h6>
                            </div>
                        </div>
                    </b-media>
                    <div class="mt-1 text-left">
                        <hr>
                        <div id="bitbucket_commits">
                            <app-timeline>
                                <app-timeline-item variant="success" v-for="commit in commitDetail.commits"
                                    :key="commit.id" class="pb-2 pl-2" style="line-break: anywhere;">
                                    <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-50">
                                        <h6 class="mb-0"> {{commit.repo}}</h6>
                                        <small
                                            class="timeline-item-time text-nowrap text-muted ml-1">{{dateFormat(commit.date)}}</small>
                                    </div>
                                    <p class="mb-0">
                                        {{commit.message}}
                                        <span class="links d-block mt-25">
                                            <a v-if="commit.link" class="d-inline mr-25" :href="commit.link" target="_blank"  rel="noreferrer noopener">
                                                <b-badge variant="light-secondary">Asana <feather-icon icon="ExternalLinkIcon"/></b-badge>
                                            </a>
                                            <a class="d-inline" :href="'https://bitbucket.org/gameguru/'+commit.repoName+'/commits/'+commit.hash" target="_blank"  rel="noreferrer noopener">
                                                <b-badge variant="light-secondary">Bitbucket <feather-icon icon="GitCommitIcon"/></b-badge>
                                            </a>
                                        </span>
                                    </p>
                                </app-timeline-item>
                            </app-timeline>
                        </div>
                    </div>
                </div>
            </div>
        </b-card-text>
        <div v-else-if="isLoading" class="text-center my-2">
            <b-spinner class="d-block mx-auto mb-50"></b-spinner>
            <strong>Loading...</strong>
        </div>
    </b-modal>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse,BFormCheckbox ,VBModal ,BModal,BButton,BCardText,BBadge,BAvatarGroup, BAvatar, VBTooltip,BMediaAside,BMedia,BMediaBody,BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { mapState } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment'
export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCollapse,
        BFormCheckbox,
        BModal,
        BButton,
        BCardText,
        BBadge,
        BAvatarGroup,
        BAvatar,
        BMediaAside,
        BMedia,
        BMediaBody,
        BSpinner,
        AppTimeline,
        AppTimelineItem,
    },
    props: {
        modalDate: {
            type: String,
            required: false,
        },
        commits: {}
    },
    directives: {
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
        Ripple,
    },
    setup() {
        return {
            avatarText,
        }
    },
    computed: {
        ...mapState('bitbucketStore', ['commitDetail', 'isLoading'])
    },
    methods: {
        dateFormat(date) {
            return moment(date).format('HH:mm');
        },
        resetDetail() {
            this.$store.dispatch('bitbucketStore/resetDetailView');
        }
    }
}
</script>
<style>
#bitbucket_commits::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}
#bitbucket_commits::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

#bitbucket_commits::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 100px;
}
#bitbucket_commits{
	overflow: auto;
	max-height: 400px;
    padding-right: 10px;
    padding-top: 10px;
}
#bitbucket_commits .timeline-item-point{
    top:3px;
}
</style>